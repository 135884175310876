import clsx from 'clsx';
import AnchorLink from "./common/AnchorLink";
import PulseBorder from './common/PulseBorder';
import styles from '../styles/HomeSectionBanner.module.scss';
import { useEffect, useState } from 'react';
import InfoTooltip from './common/InfoTooltip';

function HomeSectionBanner() {
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 768;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  return (
    <section
      className={clsx(styles["c-home-section-banner"], "c-home-section-banner", "c-home-section")}
      id="id_section_banner"
    >

      <div className="c-container">

        <div className="c-home-section-banner-content">

          <PulseBorder extraClassName="c-home-section-banner-pulse-border"/>

          <h2 className="c-heading c-home-section-banner-content-title">
            Redefine your DEXperience with <strong className="c-home-section-banner-content-title-strong">DexPal</strong>
          </h2>

          <span className="c-home-section-banner-content-subtitle">
            The ultimate dashboard for trading derivatives on&nbsp;<InfoTooltip text='DEXs.' tooltip='DEX: Decentralized Exchange' iconSize='var(--px12)' />
          </span>

          <span className="c-home-section-banner-content-subtitle">
            Bridging the {isMobile ? 'UX' : 'user experience'} gap between centralized and decentralized exchanges.
          </span>

          <AnchorLink
            extraClassName="c-home-section-banner-content-anchor"
            url="/#id_section_about"
            title="See more"
          />

        </div>

      </div>

    </section>
  )
}

export default HomeSectionBanner
