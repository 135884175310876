import HomeMascotAnimation from "./HomeMascotAnimation";
import HomeEllipseAnimation from "./HomeEllipseAnimation";

function HomeSectionHeroAnimation() {

  return (
    <div className="c-home-section-hero-animation">
      <div className="c-home-section-hero-mascot">
        <HomeMascotAnimation extraClassname="c-home-section-hero-mascot-animation"/>
      </div>
      <HomeEllipseAnimation/>
    </div>
  );
}

export default HomeSectionHeroAnimation;
