import clsx from 'clsx';
import {HomeSectionAboutListContent} from "../mocks/content";
import HomeSectionAboutListItem from "./HomeSectionAboutListItem";
import HomeEllipseAnimation from "./HomeEllipseAnimation";
import styles from '../styles/HomeSectionAbout.module.scss';

function HomeSectionAbout() {

  return (
    <section
      className={clsx(styles["c-home-section-about"], "c-home-section-about", "c-home-section")}
       id="id_section_about"
    >

      <div className="c-home-section-about-animation">

        <div className="c-home-section-about-animation-container">
          <div className="c-home-section-about-animation-inner">
            <HomeEllipseAnimation isOvalAnimation extraClassName="c-home-section-about-animation"/>
          </div>
        </div>

      </div>

      <div className="c-container">

        <ul className="c-home-section-about-list">

          {HomeSectionAboutListContent.map((paragraph, idx) => (
            <HomeSectionAboutListItem
              key={idx}
              heading={paragraph.heading}
              text={paragraph.text}
            />
          ))}

        </ul>

      </div>

    </section>
  )
}

export default HomeSectionAbout
