import { Link } from "react-router-dom";
import clsx from "clsx";
import { ReactComponent as IcnX } from "../../assets/icons/icn-twitter-x.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/icons/icn-hamburger-menu.svg";
import { SetStateAction } from "react";

interface NavItemsProps {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: React.Dispatch<SetStateAction<boolean>>;
}

function NavItems({ isMobileMenuOpen, setIsMobileMenuOpen }: NavItemsProps) {
  return (
    <div className={clsx("c-navbar-nav")}>
      <ul className={`c-navbar-nav-items ${isMobileMenuOpen && "expanded"}`}>
        <li>
          <Link
            to="/#id_section_banner"
            title="DEXperience"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            DEXperience
          </Link>
        </li>
        <li>
          <Link
            to="/#id_section_about"
            title="Why DexPal?"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Why DexPal?
          </Link>
        </li>
        <li>
          <Link
            to="/#id_section_advantage"
            title="Advantages"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Advantages
          </Link>
        </li>
        {/* <li>
          <Link
            to="/#id_section_about_us"
            title="About Us"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            About Us
          </Link>
        </li> */}
        <li>
          <a
            href="https://twitter.com/"
            target="_blank"
            rel="noreferrer"
            className="c-nav-link icon"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <IcnX />
          </a>
        </li>
      </ul>
      <Link
        to="/#id_section_form"
        className={clsx("c-button || white-outline")}
        title="Join the waitlist"
      >
        Join the waitlist
      </Link>
      <a
        onClick={() => setIsMobileMenuOpen((isMobileMenuOpen) => !isMobileMenuOpen)}
        className="c-nav-link icon hamburger"
      >
        <HamburgerIcon className="hamburger-icon" />
      </a>
    </div>
  );
}

export default NavItems;
