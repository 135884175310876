import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../form-elements/CustomInput";
import CustomSelect from "../form-elements/CustomSelect";
import Button from "../common/Button";
import { toast } from "react-toastify";
import emailjs from "emailjs-com";

// Define Yup schema for validation
const schema = yup.object().shape({
  investor_fullname: yup.string().required("Full name is required"),
  investor_email_address: yup
    .string()
    .email("Invalid email address")
    .required("Email address is required"),
  investor_type: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .test("selectRequired", "Organization type is required", (value) => {
      return value && value.value !== undefined;
    }),
});

interface FormValues {
  investor_fullname: string;
  investor_email_address: string;
  investor_type: { value?: string };
}

const options = [
  { label: "Dex", value: "dex" },
  { label: "Partner (non-Dex)", value: "partner" },
  { label: "Foundation", value: "foundation" },
  { label: "DAO", value: "dao" },
  { label: "Investor", value: "investor" },
];

function HomeInvestorForm() {
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FormValues>({
    defaultValues: {
      investor_fullname: "",
      investor_email_address: "",
      investor_type: undefined,
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: FormValues) => {
    emailjs
      .send(
        "service_gmh7s1k",
        "template_jlatqbl",
        {
          name: data.investor_fullname,
          email: data.investor_email_address,
          type: data.investor_type.value,
        },
        "EZjNIPSt6UVIeyp04"
      )
      .then(
        (result) => {
          toast.success(
            "Thank you for your interest. We will get in touch with you very shortly.",
            {
              toastId: "id_toast_investor_success",
            }
          );
          resetForm();
        },
        (error) => {
          console.log(error.text);
          toast.error(
            "Something went wrong when submitting the form. Please use another email or try again later.",
            {
              toastId: "id_toast_investor_error",
            }
          );
        }
      );
  };

  return (
    <form method="post" className="c-form c-form-home-investor" onSubmit={handleSubmit(onSubmit)}>
      <CustomInput
        name="investor_fullname"
        labelText="Full name"
        placeholder="Full name"
        control={control}
      />
      <CustomInput
        type="email"
        name="investor_email_address"
        labelText="Email Address"
        placeholder="Email Address"
        control={control}
      />

      <CustomSelect
        name="investor_type"
        items={options}
        labelText="Select Organization type"
        placeholder="Organization type"
        control={control}
      />

      <Button type="submit" btnColor="blue" title="Submit this form" text="Submit form" />

      <div className="visuallyHidden" aria-hidden="true">
        <input type="text" name="_gotcha" tabIndex={-1} autoComplete="off" />
      </div>
    </form>
  );
}

export default HomeInvestorForm;
