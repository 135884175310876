import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import clsx from "clsx";
import BaseLayout from "../components/layouts/BaseLayout";
import HomeSectionHero from "../components/HomeSectionHero";
import HomeSectionAbout from "../components/HomeSectionAbout";
import HomeSectionBanner from "../components/HomeSectionBanner";
import HomeSectionAdvantage from "../components/HomeSectionAdvantage";
import HomeSectionForms from "../components/HomeSectionForm";
import HomeSectionAboutUs from "../components/HomeSectionAboutUs";
import styles from "../styles/Home.module.scss";

gsap.registerPlugin(ScrollTrigger);

function Home() {
  const container = useRef<any>(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.set(".c-header", { opacity: 0, y: "0.5em" });
      gsap.to(".c-header", {
        y: "0",
        duration: 1.2,
        opacity: 1,
        ease: "power1.out",
      });

      // gsap.set(".c-home-section-hero-title .c-heading", {opacity: 0, x:'-3em'});
      // gsap.set(".c-home-section-hero-input-group", {opacity: 0, x:'-4em'});
      // const heroTl = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: '.c-home-section-hero',
      //     start: "top bottom",
      //     end: "top center",
      //   },
      // });
      //
      // heroTl.to('.c-home-section-hero-title .c-heading',{
      //   x: 0,
      //   opacity: 1,
      //   ease: "power1.out"
      // })
      //
      // heroTl.to(".c-home-section-hero-input-group", {
      //   x: 0,
      //   opacity: 1,
      //   ease: "power1.out"
      // });

      const aboutCards = gsap.utils.toArray(".c-home-section-about-list-item") as HTMLElement[];
      gsap.set(aboutCards, { opacity: 0, y: "3em" });

      aboutCards.forEach((card, idx) => {
        gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            scrub: true,
            start: "top bottom",
            end: "top center",
          },
          stagger: 1.2,
          opacity: 1,
          y: 0,
          ease: "power1.in",
        });
      });

      gsap.set(".c-home-section-banner-pulse-border", { opacity: 0, y: "3em" });
      gsap.set(".c-home-section-banner-content-title", { opacity: 0, y: "3em" });
      gsap.set(".c-home-section-banner-content-subtitle", { y: "3em", opacity: 0 });
      gsap.set(".c-home-section-banner-content-anchor", { y: "3em", opacity: 0 });

      var bannerTl = gsap.timeline({
        scrollTrigger: {
          trigger: ".c-home-section-banner",
          start: "top 60%",
          end: "top 10%",
          scrub: true,
        },
      });
      bannerTl.to(".c-home-section-banner-pulse-border", {
        opacity: 1,
        y: 0,
      });

      bannerTl.to(".c-home-section-banner-content-title", {
        opacity: 1,
        y: 0,
      });

      bannerTl.to(".c-home-section-banner-content-subtitle", {
        opacity: 1,
        y: 0,
      });

      bannerTl.to(".c-home-section-banner-content-anchor", {
        opacity: 1,
        y: 0,
      });

      const advantageCards = gsap.utils.toArray(
        ".c-home-section-advantage-card-list-item"
      ) as HTMLElement[];
      gsap.set(advantageCards, { opacity: 0, y: "3em" });

      advantageCards.forEach((card, idx) => {
        gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            scrub: true,
            start: "top bottom",
            end: "top center",
          },
          opacity: 1,
          y: 0,
          ease: "power1.in",
        });
      });

      const formItems = gsap.utils.toArray(".c-home-section-form-grid-item") as HTMLElement[];
      gsap.set(formItems, { opacity: 0, y: "3em" });

      const formItemsTl = gsap.timeline({
        scrollTrigger: {
          trigger: ".c-home-section-form",
          start: "top 80%",
          end: "top center",
          scrub: true,
        },
      });

      formItems.forEach((item, idx) => {
        formItemsTl.to(item, {
          opacity: 1,
          y: 0,
          ease: "power1.out",
        });
      });

      ScrollTrigger.refresh();
    }, container);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={container}>
      <BaseLayout pageTitle="Home" pageClassName={clsx(styles["home"], "home")}>
        <HomeSectionHero />
        <HomeSectionBanner />
        <HomeSectionAbout />
        <HomeSectionAdvantage />
        <HomeSectionForms />
        {/* <HomeSectionAboutUs/> */}
      </BaseLayout>
    </div>
  );
}

export default Home;
