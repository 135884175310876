import CustomInput from "../form-elements/CustomInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../common/Button";
import { toast } from "react-toastify";
import emailjs from "emailjs-com";

// Define Yup schema for validation
const schema = yup.object().shape({
  join_email_address: yup
    .string()
    .email("Invalid email address")
    .required("Email address is required"),
});

interface FormValues {
  join_email_address: string;
}

function HomeJoinForm() {
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FormValues>({
    defaultValues: {
      join_email_address: "", // Set default value for your email field
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    emailjs
      .send(
        "service_gmh7s1k",
        "template_h645jns",
        {
          email: data.join_email_address,
        },
        "EZjNIPSt6UVIeyp04"
      )
      .then(
        (result) => {
          toast.success(
            "Thank you for joining the waitlist. We will keep you updated with the latest developments.",
            {
              toastId: "id_toast_join_success",
            }
          );
          resetForm();
        },
        (error) => {
          console.log(error.text);
          toast.error(
            "Something went wrong when submitting the form. Please use another email or try again later.",
            {
              toastId: "id_toast_join_error",
            }
          );
        }
      );
  };

  return (
    <form method="post" className="c-form c-form-home-join" onSubmit={handleSubmit(onSubmit)}>
      <CustomInput
        type="email"
        name="join_email_address"
        labelText="Email Address"
        placeholder="Email Address"
        control={control}
      />

      <Button type="submit" btnColor="purple" title="Join waitlist" text="Join the waitlist" />

      <div className="visuallyHidden" aria-hidden="true">
        <input type="text" name="_gotcha" tabIndex={-1} autoComplete="off" />
      </div>
    </form>
  );
}

export default HomeJoinForm;
