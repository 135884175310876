import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CustomInputGroup from "../form-elements/CustomInputGroup";
import emailjs from "emailjs-com";

// Define Yup schema for validation
const schema = yup.object().shape({
  hero_join_email_address: yup
    .string()
    .email("Invalid email address")
    .required("Email address is required"),
});

interface FormValues {
  hero_join_email_address: string;
}

function HomeHeroJoinForm() {
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FormValues>({
    defaultValues: {
      hero_join_email_address: "", // Set default value for email field
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    emailjs
      .send(
        "service_gmh7s1k",
        "template_h645jns",
        {
          email: data.hero_join_email_address,
        },
        "EZjNIPSt6UVIeyp04"
      )
      .then(
        (result) => {
          toast.success(
            "Thank you for joining the waitlist. We will keep you updated with the latest developments.",
            {
              toastId: "id_toast_hero_join_success",
            }
          );
          resetForm();
        },
        (error) => {
          console.log(error.text);
          toast.error(
            "Something went wrong when submitting the form. Please use another email or try again later.",
            {
              toastId: "id_toast_hero_join_error",
            }
          );
        }
      );
  };

  return (
    <form method="post" className="c-form c-form-home-hero-join" onSubmit={handleSubmit(onSubmit)}>
      <CustomInputGroup
        type="email"
        extraClassName="c-home-section-hero-input-group"
        name="hero_join_email_address"
        labelText="Email Address"
        placeholder="Email address"
        control={control}
        button={{
          type: "submit",
          text: "Join the waitlist",
          title: "Join waitlist",
        }}
      />

      <div className="visuallyHidden" aria-hidden="true">
        <input type="text" name="_gotcha" tabIndex={-1} autoComplete="off" />
      </div>
    </form>
  );
}

export default HomeHeroJoinForm;
