import clsx from 'clsx';
import {HomeSectionAdvantageCardContent} from '../mocks/content';
import HomeSectionAdvantageListItem from './HomeSectionAdvantageListItem';
import HomeMascotAnimation from "./HomeMascotAnimation";
import styles from '../styles/HomeSectionAdvantage.module.scss';

function HomeSectionAdvantage() {
  return (
    <section
      id="id_section_advantage"
      className={clsx(styles["c-home-section-advantage"], "c-home-section-advantage", "c-home-section")}
    >
      <div className="c-container">

        <div className="c-home-section-advantage-grid">

          <div className="c-home-section-advantage-grid-item title">

            <div className="c-home-section-advantage-grid-item-title-inner">

              <h2 className="c-heading h1 c-home-section-advantage-grid-item-title">
                The DexPal <strong className="c-home-section-advantage-grid-item-title-strong">advantage</strong>.
              </h2>

             <div className="c-home-section-advantage-mascot">
               <HomeMascotAnimation extraClassname="c-home-section-advantage-mascot-animation"/>
             </div>

            </div>

          </div>

          <div className="c-home-section-advantage-grid-item list">

            <ol className="c-home-section-advantage-card-list">
              {HomeSectionAdvantageCardContent.map((card, idx) => (
                <HomeSectionAdvantageListItem
                  key={idx}
                  index={idx}
                  title={card.title}
                  text={card.text}
                />
              ))}
            </ol>

          </div>

        </div>

      </div>

    </section>
  )
}

export default HomeSectionAdvantage;