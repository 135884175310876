import clsx from "clsx";
import styles from "../../styles/Footer.module.scss";

function Footer() {
  return (
    <footer className={clsx(styles["c-footer"], "c-footer")}>
      <div className="c-container">
        <nav>
          <span className="c-heading">Built in Montreal</span>
          <span className="c-heading">For degens, by degens.</span>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
