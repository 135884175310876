import React from 'react';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./Routes";
import {ToastContainer} from "react-toastify";

function App() {
  return (
    <BrowserRouter>
      <AppRoutes/>
      <ToastContainer
        limit={1}
        draggable={false}
        position="top-right"
        autoClose={8000}
      />
    </BrowserRouter>
  );
}

export default App;
