import HomeJoinForm from "../components/forms/HomeJoinForm";
import HomeInvestorForm from "../components/forms/HomeInvestorForm";
import Ellipse1Icn1 from "../assets/images/dexpal-mascot-default-ring-1-1.webp";
import Ellipse1Icn2 from "../assets/images/dexpal-mascot-default-ring-1-2.webp";
import Ellipse1Icn3 from "../assets/images/dexpal-mascot-default-ring-1-3.webp";
import Ellipse2Icn1 from "../assets/images/dexpal-mascot-default-ring-2-1.webp";
import Ellipse2Icn2 from "../assets/images/dexpal-mascot-default-ring-2-2.webp";
import Ellipse2Icn3 from "../assets/images/dexpal-mascot-default-ring-2-3.webp";
import InfoTooltip from '../components/common/InfoTooltip';

export const HomeSectionAboutListContent = [
  {
    heading: "Where we are at",
    text: <>Over 98% of the $30+ trillion crypto derivatives trading occurs on <InfoTooltip text='CEXs' tooltip='CEX: Centralized Exchange' iconColor='var(--white-transparent-55)' iconSize='var(--px8)'/>, <strong>leaving <InfoTooltip text='DEXs' tooltip='DEX: Decentralized Exchange' iconSize='var(--px8)'/> in the dust</strong> with less than 2%!</>
  },
  {
    heading: "Why we’re here",
    text: <>Although DEXs have become more efficient, the <strong>poor user experience</strong> offered to traders is <strong>deterring wider adoption</strong>.</>
  },
  {
    heading: "Where we’re going",
    text: <>It’s time for <strong>derivatives trading to join the DEX side</strong>.</>
  },
]

export const HomeSectionAdvantageCardContent = [
  {
    title: "Convenience",
    text: <>Aggregate your trading activity across multiple wallets and <InfoTooltip text='DEXs' tooltip='DEX: Decentralized Exchange' iconColor='var(--white-transparent-55)'/> into one dashboard.</>
  },
  {
    title: "Accounting",
    text: "Easily compile and export your aggregated transactions to CSV for simplified accounting."
  },
  {
    title: "Analytics",
    text: "Gain insights and analytics on your performance for smarter trading decisions."
  },
  {
    title: "Value",
    text: "Easily find trading pairs and compare fees to get the best bang for your buck."
  },
  {
    title: "Leaderboards",
    text: "Stay ahead of the curve by following smart money moves and top performing traders."
  },
  {
    title: "Alerts",
    text: "Get instant updates for crucial trading events on your mobile with customizable alerts."
  },
  {
    title: "News",
    text: "Stay in the loop on trading competitions, incentive programs, new listings, and other news."
  },
  {
    title: "Knowledge Base",
    text: "Discover how to navigate DEXs with FAQs, guides, video tutorials, and community support."
  },
]

export const HomeSectionFormItems = [
  {
    id: "waitlist",
    title:  <>Join the waitlist and <strong>secure your spot</strong> today.</>,
    subtitle: "DexPal Beta access users will receive exclusive perks, including a chance to score a DexPal Genesis NFT.",
    form: <HomeJoinForm/>
  },
  {
    id: "investors",
    title:  <>Interested in <strong>working</strong> or <strong>building with us</strong>?</>,
    subtitle:<>If you are a <InfoTooltip text='DEX' tooltip='DEX: Decentralized Exchange' iconColor='var(--white-transparent-55)'/>, Foundation, DAO, investor or potential partner interested in working with DexPal or having access to our APIs please fill this form.</>,
    form: <HomeInvestorForm/>
  }
]

export const EllipseAnimationIcons = [
  {group: [
      {title: "Crypto 1", icon: Ellipse1Icn1},
      {title: "Crypto 2", icon: Ellipse1Icn2},
      {title: "GMX", icon: Ellipse1Icn3}
    ]
  },
  {group: [
      {title: "Crypto 4", icon: Ellipse2Icn1},
      {title: "Vela", icon: Ellipse2Icn2},
      {title: "Crypto 5", icon: Ellipse2Icn3}
    ]
  },
  {group: []}
]