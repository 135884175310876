import ErrorLayout from '../components/layouts/ErrorLayout';
import clsx from 'clsx';
import styles from '../styles/Error.module.scss';
import {Link} from "react-router-dom";
function Error404(){
  return (
    <ErrorLayout
      pageTitle="Error 404"
      pageClassName="error"
    >
      <section className={clsx(styles["c-error-section"], "c-error-section")}>

        <div className="c-container">

          <div className="c-error-section-inner">

            <h1 className="c-error-section-title">404</h1>

            <h2 className="c-heading h3">
              Oops...looks like you got lost.<br/> Let's get you back on track!
            </h2>

            <div className="c-button-container">

            <Link
              to="/"
              className="c-button gradient"
            >
              Back to Homepage
            </Link>

            </div>

          </div>

        </div>

      </section>

    </ErrorLayout>
  )
}

export default Error404;