import clsx from 'clsx';
import {HomeSectionFormItems} from "../mocks/content";
import styles from '../styles/HomeSectionForm.module.scss';

function HomeSectionForm() {
  return (
    <section
      id="id_section_form"
      className={clsx(styles["c-home-section-form"], "c-home-section-form", "c-home-section")}
    >

      <div className="c-container">

        <div className="c-home-section-form-grid">

          {HomeSectionFormItems.map((form, idx) => (
            <div key={idx} className={clsx("c-home-section-form-grid-item", form.id)}>

              <div className="c-home-section-form-grid-item-container">

                <div className="c-home-section-form-grid-item-inner">

                  <h2 className="c-heading h1">
                    {form.title}
                  </h2>

                  <h3 className="c-heading">
                    {form.subtitle}
                  </h3>

                  {form.form}

                </div>

              </div>

            </div>

          ))}

        </div>

      </div>

    </section>
  )
}

export default HomeSectionForm;
