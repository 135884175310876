import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import Error404 from "./views/Error404";

const AppRoutes = () => {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
  );
};

export default AppRoutes;