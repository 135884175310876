import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Logo from './Logo';
import NavItems from './NavItems';
import styles from '../../styles/Header.module.scss';

function Header() {
  const [scrollingDown, setScrollingDown] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsMobileMenuOpen(false);
      const scrollTop = window.scrollY;

      setScrollingDown(Boolean(scrollTop > (window as any).lastScrollTop || 0));
      (window as any).lastScrollTop = scrollTop;
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <header className={clsx(styles["c-header"], "c-header")}>
      <nav className={clsx("c-navbar c-container", scrollingDown && 'scrolled-down')}>
        <Logo
          isLink
          hasMobileVersion
        />
        <NavItems isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} />
      </nav>
    </header>
  )
}

export default Header