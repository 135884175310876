import {ButtonHTMLAttributes} from 'react';
import clsx from 'clsx';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "submit" | "reset";
  extraClassname?: string;
  btnColor: "white-outline"| "purple" | "blue" | "gradient";
  title: string;
  text: string;
}

function Button(
  {
    type,
    extraClassname,
    btnColor,
    title,
    text
  }: ButtonProps) {
  return (
    <button
      type={type ? type : "button"}
      className={clsx("c-button", btnColor? btnColor: 'white-outline',extraClassname && extraClassname)}
      title={title}
    >
      <span> {text? text : "button text"}</span>
    </button>
  )
}

export default Button