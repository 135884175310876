import clsx from 'clsx';
import styles from '../../styles/PulseBorder.module.scss';
interface PulseBorderProps {
  extraClassName?: string;
  isVertical?: boolean;
}

function PulseBorder({extraClassName, isVertical}: PulseBorderProps) {
  return (
    <div className={clsx(styles["c-pulse-border"], "c-pulse-border", isVertical && "is-vertical", extraClassName && extraClassName)}>
      <span className="border"/>
       <span className="halo"/>
    </div>
  )
}

export default PulseBorder;