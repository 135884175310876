import {ReactNode, useEffect} from "react";
import Helmet from "react-helmet";
import clsx from "clsx";

interface ErrorLayoutProps {
  pageTitle: string;
  pageClassName: any;
  children: ReactNode;
}

function ErrorLayout({children, pageTitle, pageClassName}:ErrorLayoutProps) {

  useEffect(() => {
    // Add the body class if bodyClassName is provided
    if (pageClassName) {
      document.body.classList.add(`c-body-${pageClassName}`);

      // Clean up by removing the class when the component unmounts
      return () => {
        document.body.classList.remove(`c-body-${pageClassName}`);
      };
    }
  }, [pageClassName]); // Only re-run the effect if bodyClassName changes

  return (
    <>
      <Helmet>
      <title>DexPal | {pageTitle}</title>
      </Helmet>

      <main className={clsx(`c-main-${pageClassName}`)}>
        {children}
      </main>

    </>
  )
}

export default ErrorLayout