import React, {ReactNode} from 'react';
import { Controller, Control } from "react-hook-form";
import clsx from 'clsx';
import Select, { components, DropdownIndicatorProps, Props as SelectProps, NoticeProps } from 'react-select';
import {ReactComponent as IcnCaretDown} from '../../assets/icons/icn-caret-down.svg';
import styles from '../../styles/CustomSelect.module.scss';

export interface ListItemsProps {
  value: string;
  label: string;
}

interface CustomSelectProps extends Partial<SelectProps> {
  extraClassName?: string;
  name: string;
  placeholder?: string;
  items: ListItemsProps[];
  inputValue?: string;
  labelText: string;
  hiddenLabel?: boolean;
  disabled?: boolean;
  helpText?: ReactNode;
  control: Control<any>; // This should be the control prop from useForm
}

function CustomSelect (
  {
    extraClassName,
    name,
    placeholder,
    items,
    inputValue,
    labelText,
    hiddenLabel=true,
    disabled,
    helpText,
    control,
    ...rest
  }: CustomSelectProps) {

  const DropdownIndicator = ({ ...props }: DropdownIndicatorProps) => (
    <components.DropdownIndicator {...props}>
      <IcnCaretDown/>
    </components.DropdownIndicator>
  );

  const NoOptionsMessage = ({ ...props }: NoticeProps) => (
    <components.NoOptionsMessage {...props}>No results found</components.NoOptionsMessage>
  );

  return (
    <div className={clsx("c-form-group", extraClassName && extraClassName)}>

      <Controller
        name={name}
        control={control}
        render={({ field, fieldState:{error, invalid} }) =>
          <>
            <Select
              classNamePrefix="c-select"
              className={clsx(styles["c-select"], "c-select", invalid && "is-invalid")}
              inputId={`id_${name}`}
              placeholder={placeholder ? placeholder : ""}
              options={items}
              aria-invalid={!!error}
              inputValue={inputValue}
              components={{
                DropdownIndicator,
                NoOptionsMessage,
              }}
              {...field}
              {...rest}
            />
            <label
              className={clsx("c-label", disabled && "disabled", hiddenLabel && "visuallyHidden")}
              htmlFor={`id_${name}`}
              title={labelText}
            >
              {labelText && labelText}
            </label>

            {helpText && <small className="c-form-help-text">{helpText}</small>}
            {error && (
              <small className="c-form-error">{error.message}</small>
            )}
          </>
        }
      />
    </div>
  );
}

export default CustomSelect;
