import React, {ButtonHTMLAttributes,InputHTMLAttributes, ReactNode} from "react";
import { Controller, Control } from "react-hook-form";
import clsx from "clsx";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "submit" | "reset";
  title: string;
  text: string;
}

interface CustomInputGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  extraClassName?: string;
  type?: string;
  name: string;
  hiddenLabel?: boolean;
  labelText: string;
  disabled?: boolean;
  button: ButtonProps;
  helpText?: ReactNode;
  control: Control<any>; // This should be the control prop from useForm
}

function CustomInputGroup(
  {
    extraClassName,
    type,
    name,
    labelText,
    hiddenLabel=true,
    disabled,
    button,
    helpText,
    control,
    ...rest
  }: CustomInputGroupProps) {

  return (
    <div className={clsx("c-input-group", extraClassName && extraClassName)}>

      <div className="c-input-group-inner">

        <Controller
          name={name}
          control={control}
          render={({ field, fieldState:{error} }) =>
            <>
              <div className={clsx("c-input-group-input", error && "is-invalid")}>

                <input
                  type={type ? type : "text"}
                  id={`id_${name}`}
                  className={clsx("c-input")}
                  disabled={disabled}
                  {...field}
                  {...rest}
                />
                <button
                  type={button.type ? button.type : "button"}
                  className={clsx("c-button", "gradient")}
                  title={button.title}
                >
                  <span> {button.text? button.text : "button text"}</span>
                </button>

              </div>

              <label
                className={clsx("c-label", disabled && "disabled", hiddenLabel && "visuallyHidden")}
                htmlFor={`id_${name}`}
                title={labelText}
              >
                {labelText && labelText}
              </label>

              {helpText && <small className="c-form-help-text">{helpText}</small>}
              {error && (
                <small className="c-form-error">{error.message}</small>
              )}
            </>
          }
        />

      </div>

    </div>
  );
}

export default CustomInputGroup;
