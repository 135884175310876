import clsx from "clsx";
import { ReactComponent as InfoIcon } from '../../assets/icons/icn-info.svg';
import styles from '../../styles/InfoTooltip.module.scss';
import Tippy from '@tippyjs/react';

interface InfoTooltipProps {
    extraClassName?: string;
    text: string;
    tooltip: string;
    iconColor?: string;
    iconSize?: string;
}
  
function InfoTooltip({extraClassName, text, tooltip, iconColor = '#fff', iconSize = '15px'}: InfoTooltipProps) {
    return (
        <div className={clsx(styles["c-info-tooltip"], "c-info-tooltip", extraClassName && extraClassName )}>
            <Tippy content={tooltip}>
                <div className="c-info-tooltip-content">
                    { text }
                    <InfoIcon className="c-info-tooltip-content-info-icon" style={{ fill: iconColor, width: iconSize, height: iconSize }}/> 
                </div>
            </Tippy>
        </div>
    );
}
  
export default InfoTooltip;