import {NavLink} from 'react-router-dom'
import clsx from 'clsx';
import useMobileMediaQueries from '../../hooks/useMediaQueries';
import {ReactComponent as MobileLogo} from '../../assets/images/dexpal-logo-icon.svg';
import {ReactComponent as DesktopLogo} from '../../assets/images/dexpal-logo.svg';
import styles from '../../styles/Logo.module.scss';
interface LogoProps {
  isLink: boolean;
  hasMobileVersion?: boolean;
}

function Logo({isLink, hasMobileVersion}: LogoProps) {
  const {isMobile} = useMobileMediaQueries();

  return (
    <>
      {isLink? (
        <NavLink
          className={clsx(styles["c-logo"], "c-logo", hasMobileVersion && "with-mobile-version")}
          to='/'
          title="Go to homepage"
        >

          {hasMobileVersion? (
            <>
              {isMobile? (<MobileLogo/>):(<DesktopLogo/>)}
            </>
          ):(
            <DesktopLogo/>
          )}

        </NavLink>
      ):(
        <span
          className={clsx("c-logo", hasMobileVersion && "with-mobile-version")}
          aria-label="Dexpal Logo"
        >
          {hasMobileVersion? (
            <>
              {isMobile? (<MobileLogo/>):(<DesktopLogo/>)}
            </>
          ): (
            <DesktopLogo/>
          )}
        </span>
      )}
    </>
  )
}

export default Logo
