import { ReactNode } from "react";

interface HomeSectionAdvantageListItemProps {
  index: number;
  title: string;
  text: string | ReactNode;
}

function HomeSectionAdvantageListItem({index, title, text}:HomeSectionAdvantageListItemProps) {
  return (
    <li className="c-home-section-advantage-card-list-item">

      <article className="c-home-section-advantage-card">

        <span className="c-home-section-advantage-card-index">
          /{index < 9 && 0}{index + 1}
        </span>

        <h3 className="h2">
          {title}
        </h3>

        <p className="c-heading">
          {text}
        </p>

      </article>

    </li>
  )
}

export default HomeSectionAdvantageListItem;