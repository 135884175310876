import clsx from 'clsx';
import useMobileMediaQueries from "../hooks/useMediaQueries";
import PulseBorder from './common/PulseBorder';
import HomeHeroJoinForm from './forms/HomeHeroJoinForm';
import HomeSectionHeroAnimation from "./HomeSectionHeroAnimation";
import AnchorLink from "./common/AnchorLink";
import styles from '../styles/HomeSectionHero.module.scss';

function HomeSectionHero() {
  const {isIpad} = useMobileMediaQueries();

  return (
    <section className={clsx(styles["c-home-section-hero"], "c-home-section-hero", "c-home-section")}>

      <div className="c-container">

        <div className="c-home-section-hero-grid">

          <div className="c-home-section-hero-grid-item title">

            <span className="c-home-section-hero-pulse-animation">
              <PulseBorder extraClassName="c-home-section-hero-pulse-border" isVertical={!isIpad}/>
            </span>

            <div className="c-home-section-hero-title">

              <h1 className="c-heading">
                On-chain derivatives trading, <strong>supercharged.</strong>
              </h1>

              <HomeHeroJoinForm/>

            </div>

          </div>

          <div className="c-home-section-hero-grid-item image">

            <HomeSectionHeroAnimation/>

          </div>

        </div>

        <div className="c-button-container">
          <AnchorLink
            extraClassName="c-home-section-hero-anchor"
            url="/#id_section_banner"
            title="Explore"
          />

        </div>

      </div>

    </section>
  )
}

export default HomeSectionHero