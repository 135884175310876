import clsx from 'clsx';
import {EllipseAnimationIcons} from "../mocks/content";
import styles from '../styles/HomeEllipseAnimation.module.scss';

interface HomeRingsAnimationProps {
  extraClassName?: string;
  isOvalAnimation?: boolean;
}

function HomeEllipseAnimation({extraClassName, isOvalAnimation}: HomeRingsAnimationProps) {

  return (
    <div className={clsx(styles["c-ellipse-animation"], "c-ellipse-animation", isOvalAnimation? "is-oval" : "is-round", extraClassName && extraClassName)}>
      {EllipseAnimationIcons.map((groupItem, idx) => {
        return (
          <div
            className="ellipse"
            key={idx}
          >
            {groupItem.group.map((icon, index) => (
              <div
                key={index}
                className="circle"
              >
                <span>
                  <img
                    src={icon.icon}
                    alt={icon.title}
                    width={40}
                    height={40}
                  />

                </span>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  );
}

export default HomeEllipseAnimation;
