// useMobileMediaQuery.ts

import {useMediaQuery} from 'usehooks-ts';

const useMediaQueries = (): { isMobile: boolean; isIpad: boolean } => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isIpad = useMediaQuery('(max-width: 991px)');

  return { isMobile, isIpad };
};

export default useMediaQueries;