import {ReactNode} from 'react';
import clsx from 'clsx';

interface HomeSectionAboutListItemProps {
  heading: string;
  text: ReactNode;
}

function HomeSectionAboutListItem({heading, text}: HomeSectionAboutListItemProps) {
  return (
    <li className={clsx("c-home-section-about-list-item")}>

      <span className="c-home-section-about-list-item-heading">{heading}</span>

      <h2 className="c-heading h1">
        {text}
      </h2>

    </li>
  )
}

export default HomeSectionAboutListItem;