import Mascot from '../assets/images/dexpal-mascot-default-lg.webp';
import styles from '../styles/HomeMascotAnimation.module.scss';
import clsx from "clsx";

interface MascotAnimationProps {
   extraClassname?: string;
}
function HomeMascotAnimation({extraClassname}: MascotAnimationProps) {

  return (
    <div className={clsx(styles["c-mascot-animation"], "c-mascot-animation", extraClassname && extraClassname)}>
      <img src={Mascot} alt="Dexpal Mascot" className="c-home-mascot-image"/>
    </div>
  );
}

export default HomeMascotAnimation;
