import React, {ReactNode} from "react";
import { Controller, Control } from "react-hook-form";
import clsx from "clsx";

interface CustomInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'control'> {
  extraClassName?: string;
  type?: string;
  name: string;
  labelText: string;
  hiddenLabel?: boolean;
  disabled?: boolean;
  helpText?: ReactNode;
  control: Control<any>; // This should be the control prop from useForm
}

function CustomInput(
  {
    extraClassName,
    type,
    name,
    labelText,
    hiddenLabel=true,
    disabled,
    helpText,
    control,
    ...rest
  }: CustomInputProps) {

  return (
    <div className={clsx("c-form-group", extraClassName && extraClassName)}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState:{error} }) =>
          <>
            <input
              type={type ? type : "text"}
              id={`id_${name}`}
              className={clsx("c-input", error && "is-invalid")}
              disabled={disabled}
              {...field}
              {...rest}
            />

            <label
              className={clsx("c-label", disabled && "disabled", hiddenLabel && "visuallyHidden")}
              htmlFor={`id_${name}`}
              title={labelText}
            >
              {labelText && labelText}
            </label>

            {helpText && <small className="c-form-help-text">{helpText}</small>}
            {error && (
              <small className="c-form-error">{error.message}</small>
            )}
          </>
        }
      />

    </div>
  );
}

export default CustomInput;
