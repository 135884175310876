import {Link} from 'react-router-dom';
import clsx from "clsx";
import {ReactComponent as IcnArrowDown} from '../../assets/icons/icn-arrow-down.svg';

interface AnchorLinkProps {
  extraClassName?: string;
  url: string;
  title: string;
}

function AnchorLink({extraClassName, url, title}: AnchorLinkProps) {
 return (
   <Link
     to={url}
     title={title}
      className={clsx("c-anchor-link", extraClassName && extraClassName )}
   >
      <IcnArrowDown/>
   </Link>
 )
}

export default AnchorLink;